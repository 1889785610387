<template>
	<div class="order_wrap">
		<v-container class="">
			<div class="txt_box px-6">
				<p class="txt_body1 mb-8">
					납부 금액은<span class="primary--text"
						>{{ dpParams.amount | comma }} 원</span
					>
					입니다.
					<br />아래 계좌로 지정된 금액을 입금해주세요.
				</p>
			</div>
			<div class="line_box px-6">
				<dl>
					<dt>가상계좌</dt>
					<dd>{{ dpParams.account }}</dd>
				</dl>
				<dl>
					<dt>납부기한</dt>
					<dd>{{ dpParams.date }}</dd>
				</dl>
				<dl>
					<dt>납부액</dt>
					<dd>{{ dpParams.amount | comma }} 원</dd>
				</dl>
			</div>
			<div class="txt_box mt-6 px-6">
				<span class="small"
					>※ 입금 완료 시 전자계약서 서명을 진행할 수 있으며, 입금 완료 후
					확인까지는 1분~5분 정도 소요됩니다.
				</span>
			</div>

			<div class="bottom_box px-6">
				<v-btn color="primary" class="col_1_small" @click="recpChk">
					입금 확인
				</v-btn>
				<v-btn
					depressed
					height="20"
					color=""
					class="btn_underline my-6"
					@click="onResetPayMethod"
				>
					납부방법 재선택
				</v-btn>
			</div>
			<CustomRecpDialog ref="recpPop" />
		</v-container>
	</div>
</template>
<script>
import filters from '@/mixins/filters'
// import { vaccountInstantTransfer } from '@/apis/order.recp.api'
import { postVirtualAccount } from '@/apis/order.inst.api'
import {
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import moment from 'moment'
export default {
	mixins: [filters],
	data() {
		return {
			recpInfos: {
				amount: '',
				kunnr: '',
				userId: '',
				phone: '',
				zwebRecp: '' //04:판매인 07:고객납부
			},
			vaccountParams: {
				TC_VTACCT: {},
				TO_DISPLAY: []
			},
			dpParams: {
				account: '',
				date: '',
				amount: ''
			},
			mthd: '16'
		}
	},
	mounted() {
		this.recpInfos = this.$store.getters['recp/recpInfos']
		if (!this.recpInfos.mthd) this.recpInfos.mthd = this.mthd
		this.vaccountParams = this.$store.getters['recp/vaccountParams']

		this.$log('가상계좌생성 후 납부 여부 isNew: ', this.vaccountParams.isNew)

		if (this.vaccountParams.isNew) {
			// 가상계좌생성 후 납부
			let bankName = this.vaccountParams.bankName
				? this.vaccountParams.bankName
				: ''
			this.dpParams.account = `${bankName} ${this.vaccountParams.TO_VTINFO.SUACC}`
			this.dpParams.date = ''
			this.dpParams.amount = this.recpInfos.amount
		} else {
			this.vaccountParams.TO_DISPLAY.forEach(dp => {
				const txt = dp.ZWEB_DIS_TEXT.trim()

				switch (dp.ZWEB_DIS_CD) {
					case '33':
						this.dpParams.account = dp.ZWEB_DIS_TEXT.trim()
						break
					case '40':
						this.dpParams.date = dp.ZWEB_DIS_TEXT.trim()
						break
					case '51':
						this.dpParams.amount = txt.substr(0, txt.indexOf('.'))
						break
				}
			})
		}

		//SAP에서 납부기한 내려오지 않아서 임시처리
		this.dpParams.date =
			this.dpParams.date || moment().format('YYYY년 MM월 DD일 오후 5시')
		this.$store.dispatch('recp/setDpParams', this.dpParams)
	},
	methods: {
		onResetPayMethod() {
			this.$router.go(-1)
		},
		async chkReturnTemp(prm, rtn) {
			this.$log('rtn:', JSON.stringify(rtn))
			if (rtn.TYPE === 'S') {
				// TODO store 저장
				this.$store.dispatch('recp/setRecpParams', { ...prm })

				// 웹 주문정보 저장
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']

				fetchWebOrderInfoById(mobOrderNo).then(res => {
					let webParams = res.resultObject
					let order = JSON.parse(webParams.orderInfoJson)
					order = Object.assign(order, {
						recp: {
							recpParams: this.$store.getters['recp/recpParams'],
							recpInfos: this.$store.getters['recp/recpInfos'],
							isCustSelfPay: true,
							vaccountParams: this.vaccountParams,
							dpParams: this.dpParams
							// payRecp: this.$store.getters['recp/payRecp']
						}
					})
					webParams.step = '01' // 수납
					webParams.statusCode = '108' // 서명완료
					webParams.orderInfoJson = JSON.stringify(order)

					postWebOrderInfoById(mobOrderNo, webParams).then(postRes => {
						this.$log('postRes: ', JSON.stringify(postRes))
						this.$router.push({
							name: 'ec-cust-pass',
							query: { userKey: this.$route.query.userKey }
						})
					})
				})
			} else {
				const options = {
					title: '다음과 같은 사유로 납부가 거부됐습니다.',
					guide: '납부방법을 재선택 바랍니다.',
					contents: {
						거부사유: rtn.MESSAGE
					},
					okText: '납부방법 재선택',
					cancelText: ''
				}

				const popRes = await this.$refs.recpPop.open(options)

				if (popRes) {
					// 납부방법 재선택
					this.onResetPayMethod()
				}
			}
		},
		async goRecp() {
			let prm = {}
			if (this.vaccountParams.isNew) {
				// 가상계좌생성 후 납부
				prm = {
					bankc: this.vaccountParams.bankc,
					suacc: this.vaccountParams.TO_VTINFO.SUACC,
					resId: this.recpInfos.ssn,
					depositor: this.recpInfos.name,
					okamt: this.recpInfos.amount,
					userId: this.recpInfos.userId,
					cellPhone: this.recpInfos.phone,
					kunnr: this.recpInfos.kunnr,
					zwebRecp: this.recpInfos.zwebRecp,
					payMthd: this.vaccountParams.mthd,
					mobOrderNo: this.$store.state['verify'].mobOrderNo
				}
			} else {
				prm = {
					bankc: this.vaccountParams.TC_VTACCT.BANKC,
					suacc: this.vaccountParams.TC_VTACCT.SUACC,
					resId: this.recpInfos.ssn,
					depositor: this.vaccountParams.TC_VTACCT.NAME1,
					okamt: this.recpInfos.amount,
					userId: this.recpInfos.userId,
					cellPhone: this.recpInfos.phone,
					kunnr: this.recpInfos.kunnr,
					zwebRecp: this.recpInfos.zwebRecp,
					payMthd: this.vaccountParams.mthd,
					mobOrderNo: this.$store.state['verify'].mobOrderNo
				}
			}

			// const res = await vaccountInstantTransfer(prm)	// 고객직접납부는 가상계좌수납 호출 생략
			// const rtn = res.resultObject.data.E_RETURN
			// await this.chkReturn(prm, rtn)
			const rtn = { TYPE: 'S' }
			await this.chkReturn(prm, rtn)
		},
		async recpChk() {
			// this.$alert({ message: '개발중' })
			const param = {
				webOrderId: this.$store.getters['verify/mobOrderNo'],
				customerNo: this.$store.state['verify'].customer.custNo,
				customerNm: this.$store.state['verify'].customer.name,
				codyNo: this.$store.getters['common/codyNo'],
				phone: this.$store.state['verify'].customer.phone,
				receiptAmt: this.dpParams.amount,
				custSelf: this.$store.getters['verify/custSelf']
			}
			const result = await postVirtualAccount(param)
			if (result.resultObject.data.E_RETURN.CODE === '9000') {
				const prm = {
					bankc: this.vaccountParams.bankc,
					suacc: this.vaccountParams.TO_VTINFO.SUACC,
					resId: this.recpInfos.ssn,
					depositor: this.recpInfos.name,
					okamt: this.recpInfos.amount,
					userId: this.recpInfos.userId,
					cellPhone: this.recpInfos.phone,
					kunnr: this.recpInfos.kunnr,
					zwebRecp: this.recpInfos.zwebRecp,
					payMthd: this.vaccountParams.mthd,
					mobOrderNo: this.$store.state['verify'].mobOrderNo
				}
				// const res = await vaccountInstantTransfer(prm)
				// const rtn = res.resultObject.data.E_RETURN
				await this.chkReturn(prm, result)
			} else {
				this.$alert({
					message:
						'입금이 확인되지 않습니다. <br/>가상계좌에 입금을 완료하였다면, 확인까지 1분~5분 정도 소요됩니다. <br />1분~5분 후 다시 확인해 주세요.'
				})
			}
		},
		async chkReturn(prm, rtn) {
			this.$log(rtn)
			// TODO store 저장
			this.$store.dispatch('recp/setRecpParams', { ...prm })

			// 웹 주문정보 저장
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']

			fetchWebOrderInfoById(mobOrderNo).then(res => {
				let webParams = res.resultObject
				let order = JSON.parse(webParams.orderInfoJson)
				order = Object.assign(order, {
					recp: {
						recpParams: this.$store.getters['recp/recpParams'],
						recpInfos: this.$store.getters['recp/recpInfos'],
						isCustSelfPay: this.$store.getters['recp/isCustSelfPay'],
						vaccountParams: this.vaccountParams,
						dpParams: this.dpParams
						// payRecp: this.$store.getters['recp/payRecp']
					}
				})
				webParams.step = '06' // 전자계약
				webParams.statusCode = '108' // 서명입력
				webParams.orderInfoJson = JSON.stringify(order)

				this.$alert({
					message:
						'수납이 완료됐습니다.<br/>전자계약서 발송 화면으로 이동합니다.',
					okText: '전자계약서 발송으로 이동'
				}).then(res => {
					if (res) {
						postWebOrderInfoById(mobOrderNo, webParams).then(postRes => {
							this.$log('postRes: ', JSON.stringify(postRes))
							//전자계약서 발송화면
							this.$router.push({ name: 'ec-cust-confirm' })
						})
					}
				})
			})
		}
	}
}
</script>
